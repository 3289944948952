import Vue from "vue";
import Router from "vue-router";
import { publicRoute, protectedRoute } from "./config";
import store from "@/store";
import NProgress from "nprogress";

const routes = publicRoute.concat(protectedRoute);

Vue.use(Router);
const router = new Router({
  mode: "hash",
  linkActiveClass: "active",
  routes: routes,
});
// router gards
router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.name !== "login") {
    let user = store.getters.getUser || {};
    if (to.name === "management") {
      if (user.auth === 0) {
        next();
      } else {
        next({ name: "overview", query: { redirect: to.path } });
      }
    } else {
      if (user.token && user.openId) {
        next();
      } else {
        next({ name: "login", query: { redirect: to.path } });
      }
    }
  } else {
    next();
  }
  //auth route is authenticated
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
