import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const persistedState = createPersistedState({
  storage: window.localStorage,
  key: "vue",
  render(state) {
    return { ...state };
  },
});

const store = new Vuex.Store({
  state: {
    user: null,
    mode: "light",
    themeColor: "#3f51b5",
    theme: "inidigo",
    project: null,
    dateSelect: null,
    projectName: null,
    showName: null,
    updateNews: null, // 存储已经阅读的更新内容，下次不再弹出
  },

  getters: {
    getTheme: (state) => {
      return state.theme;
    },
    getThemeColor: (state) => {
      return state.themeColor;
    },
    getProject: (state) => {
      return state.project;
    },
    getUser: (state) => {
      return state.user;
    },
    getDateSelect: (state) => {
      return state.dateSelect;
    },
    getProjectName: (state) => {
      return state.projectName;
    },
    getUpdateNews: (state) => {
      return state.updateNews;
    },
    getShowName: (state) => {
      return state.showName;
    },
  },

  mutations: {
    setThemeColor(state, payload) {
      state.themeColor = payload;
    },
    setTheme(state, payload) {
      state.theme = payload;
    },
    setProject(state, payload) {
      state.project = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setDateSelect(state, payload) {
      state.dateSelect = payload;
    },
    setProjectName(state, payload) {
      state.projectName = payload;
    },
    setUpdateNews(state, payload) {
      state.updateNews = payload;
    },
    setShowName(state, payload) {
      state.showName = payload;
    },
  },

  actions: {
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    setProject({ commit }, project) {
      commit("setProject", project);
    },
    setProjectName({ commit }, project) {
      commit("setProjectName", project);
    },
    setDateSelect({ commit }, dateSelect) {
      commit("setDateSelect", dateSelect);
    },
    setUpdateNews({ commit }, news) {
      commit("setUpdateNews", news);
    },
    setShowName({ commit }, showName) {
      commit("setShowName", showName);
    },
  },

  plugins: [persistedState],
});

export default store;
