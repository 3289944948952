<template>
  <v-app-bar color="primary" dark app>
    <v-app-bar-nav-icon @click="handleDrawerToggle" />
    <v-spacer></v-spacer>
    <v-menu bottom min-width="200px" rounded offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon x-large v-on="on">
          <v-avatar color="brown" size="46">
            <span class="white--text text-h6">{{ user.shortName }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-card style="width: 230px">
        <v-list-item-content class="justify-center">
          <div class="mx-auto text-center">
            <v-avatar color="brown">
              <span class="white--text text-h6">{{ user.shortName }}</span>
            </v-avatar>
            <h3>{{ user.name }}</h3>
            <v-card-subtitle>{{ user.account }}</v-card-subtitle>
            <div v-if="user.auth === 0">
              <div v-for="item in managementPage" :key="item.name">
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text @click="openManagementPage(item)">
                  {{ item.meta.title }}
                </v-btn>
              </div>
            </div>
            <v-divider class="my-3" />
            <v-btn depressed rounded text @click="handleLogout">登出</v-btn>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { protectedRoute } from "@/router/config";
import Vue from "vue";

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    user() {
      let user = this.$store.getters.getUser;
      if (user) {
        if (user.name.length > 2) {
          user.shortName = user.name.slice(1);
        } else {
          user.shortName = user.name;
        }
      }
      return user;
    },
    availableLanguages() {
      const { locales } = this.$vuetify.lang;
      return Object.keys(locales).map((lang) => {
        return {
          text: locales[lang].label,
          value: lang,
        };
      });
    },
    managementPage() {
      return protectedRoute[1].children;
    },
  },
  methods: {
    openManagementPage(route) {
      if (this.$router.currentRoute.name !== route.name) {
        this.$router.push({ path: route.path });
      } else {
        this.$router.go(0);
      }
    },
    handleDrawerToggle() {
      this.$emit("side-icon-click");
    },
    handleLogout() {
      Vue.$confirm("你确定要退出登录吗").then(() => {
        this.$store.commit("setUser", null);
        Vue.toasted.success("退出成功");
        this.$router.push("/auth/login");
      });
    },
  },
  created() {},
};
</script>

<style lang="sass" scoped></style>
