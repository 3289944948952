<template>
  <v-app :dark="true">
    <router-view></router-view>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card style="padding-top: 20px">
        <v-card-text style="color: black; font-size: 20px" v-html="message">
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="cancel"> 取消 </v-btn>
          <v-btn color="green darken-1" text @click="ok"> 确认 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  data() {
    return {
      dialog: false,
      message: null,
    };
  },
  methods: {
    confirm(message) {
      this.message = message;
      let _this = this;
      this.dialog = true;
      return new Promise(function (resolve, reject) {
        _this.promiseStatus = { resolve, reject };
      });
    },
    ok() {
      this.dialog = false;
      this.promiseStatus && this.promiseStatus.resolve();
    },
    cancel() {
      this.dialog = false;
      this.promiseStatus && this.promiseStatus.reject();
    },
  },
  watch: {
    $route(to, from) {
      if (from.query.h != null) {
        to.query.h = from.query.h;
      }
    },
  },
  mounted() {},
  created() {
    Vue.$confirm = this.confirm;
    // httpPost("/auth/updateNews").then((news) => {
    //   if (news) {
    //     let code = hashcode(news);
    //     if (this.$store.getters.getUpdateNews !== code) {
    //       this.confirm(news).finally(() => {
    //         this.$store.commit("setUpdateNews", code);
    //       });
    //     }
    //   }
    // });
  },
};
</script>

<style lang="sass" scoped>
.setting-fab
  top: 50% !important
  right: 0
  border-radius: 0
</style>
