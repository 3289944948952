import Vue from "vue";
import router from "./router/";
import store from "./store/";
import "./plugins/vuetify";
import "./theme/default.sass";
import "nprogress/nprogress.css";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import { Model } from "vue-api-query";
Model.$http = axios;
import "@mdi/font/css/materialdesignicons.css";
import { VContainer } from "vuetify/lib/components";
import Toasted from "vue-toasted";
Vue.use(Toasted, { position: "top-center", duration: 2000 });
Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
});

app.$mount("#app");

// 把v-container的最大宽度取消，解决页面周围的白边
VContainer.options.props.fluid.default = true;
