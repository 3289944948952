<template>
  <v-navigation-drawer
    app
    class="app-drawer"
    :mini-variant.sync="mini"
    v-model="drawer"
    :width="drawerWidth"
    :dark="$vuetify.dark"
  >
    <v-toolbar color="primary darken-1" dark>
      <v-toolbar-title>
        <v-select
          :items="projectItems"
          v-model="project"
          item-text="showName"
          item-value="id"
          outlined
          return-object
          hide-details
          @change="selectProject"
        >
          <template v-slot:selection="{ item }">
            <span
              style="
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                line-height: 1.2;
              "
            >
              {{ item.showName }}
            </span>
          </template>
        </v-select>
      </v-toolbar-title>
    </v-toolbar>
    <div class="app-drawer__inner">
      <v-list :dense="drawerWidth !== 64" class="pa-0">
        <template v-for="(item, key) in computeMenu">
          <template>
            <v-list-item :key="key" :to="item.path">
              <v-list-item-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" v-text="item.meta.icon" />
                  </template>
                  <span>{{ item.meta.title }}</span>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content v-if="drawerWidth !== 64">
                <v-list-item-title v-text="item.meta.title" />
              </v-list-item-content>
              <v-list-item-action v-if="item.meta.new">
                <v-icon color="green">mdi-new-box</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { protectedRoute as routes } from "@/router/config";
import { httpPost } from "@/util/request";
import store from "@/store";

export default {
  components: {},
  props: {
    expanded: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mini: false,
      drawerWidth: 256,
      drawer: true,
      scrollSettings: {
        maxScrollbarLength: 160,
      },
      project: null,
      projectItems: [],
    };
  },

  computed: {
    computeLogo() {
      return "/static/m.png";
    },
    computeMenu() {
      return routes[0].children.filter((a) => {
        //页面展示验证权限，默认是最低2,
        let auth = a.meta.auth;
        if (auth == null) {
          auth = 2;
        }
        return store.getters.getUser && store.getters.getUser.auth <= auth;
      });
    },
  },
  mounted() {
    this.getProjectsFromServer(() => {
      let project = store.getters.getProject;
      if (project) {
        for (let projectItem of this.projectItems) {
          if (projectItem.id === project) {
            // 如果已选的项目和有权限的项目相符，就用这个。否则就走下面的逻辑，默认选列表第一个项目
            this.project = projectItem;
            return;
          }
        }
      }
      this.selectProject(this.projectItems[0]);
    });
  },

  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    selectProject(projectItem) {
      this.project = projectItem;
      this.$store.commit("setProject", projectItem.id);
      this.$store.commit("setProjectName", projectItem.projectName);
      this.$store.commit("setShowName", projectItem.showName);
      this.$emit("projectChange", projectItem);
    },
    getProjectsFromServer(callback) {
      this.loading = true;
      httpPost("/auth/getAuthProjects")
        .then((resp) => {
          if (resp) {
            this.projectItems = resp;
          }
          if (callback && typeof callback === "function") {
            callback();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.app-drawer
  overflow: hidden !important

  &__inner
    height: calc(100vh - 100px)

  .drawer-menu--scroll
    height: calc(100vh - 48px)
    overflow: auto
</style>
