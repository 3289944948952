import axios from "axios";
import store from "@/store";
import Vue from "vue";
import router from "@/router";
// create axios
const service = axios.create({
  baseURL: process.env.VUE_APP_SERVER_API, // api base_url
  timeout: 5000000, // timeout,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

const err = (error) => {
  Vue.toasted.error(error);
  return Promise.reject(error);
};

service.interceptors.request.use((config) => {
  config.headers["Access-Control-Allow-Origin"] = "*";
  let user = store.getters.getUser || {};
  config.headers["token"] = user.token || "";
  config.headers["open_id"] = user.openId || "";
  config.headers["project"] = store.getters.getProject || "";
  return config;
}, err);

// response interceptor

service.interceptors.response.use(({ data, config }) => {
  if (data.code === 1 && config.url !== "/auth/login") {
    // 约定1为用户登录相关的错误码
    Vue.toasted.error(data.msg);
    router.push("/auth/login").then(() => {});
    return null;
  } else {
    if (data.msg) {
      Vue.toasted.show(data.msg, {
        type: data.code === 0 ? "success" : "error",
      });
    }
  }
  return data.data;
}, err);

export function httpPost(target, data) {
  return service({
    url: target,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function newProject(
  projectName,
  bigqueryProjectId,
  jsonKey,
  platform,
  appId,
  showName
) {
  let formData = new FormData();
  formData.append("projectName", projectName);
  formData.append("bigqueryProjectId", bigqueryProjectId);
  formData.append("platform", platform);
  formData.append("appId", appId);
  if (jsonKey) {
    formData.append("uploadFile", jsonKey);
  }
  if (showName) {
    formData.append("showName", showName);
  }
  return service({
    url: "/auth/newProject",
    data: formData,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
